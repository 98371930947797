export const YOUR_POINTS_TITLE = 'YOUR POINTS';
export const CHECK_LATER = 'Check back later…';
export const MIN_POINT = '0';
export const MAX_POINT = '10,000';
export const YOUR_STATUS = 'Your Status';
export const STATUS_INFO = 'Information accurate as of';
export const INCIRCLE_ENTER_PHONE_TXT =
  'Please enter your phone number to complete your InCircle Account.';
export const ENTER_NOW = 'Enter Now';
export const WELCOME_TXT = 'WELCOME';
export const ACTIVATE = 'ACTIVATE FOR TODAY';
export const ACTIVATED = 'ACTIVATED';
export const VIEW_DETAILS = 'View Details';
export const LEARN_MORE = 'Learn More';
export const YOUR_BENEFITS = 'YOUR BENEFITS';
export const BG_CREDIT_CARD = 'BG CREDIT CARD';
export const ERROR_FETCHING = 'Error fetching details';
export const MEMBER_BENEFITS = 'MEMBER BENEFITS';
export const UNLOCK_HIGHER_DESC =
  'Unlock higher Circle levels and new benefits when you spend $75,000 and more.';
export const SEE_FULL_TERMS = 'See full terms and details.';
export const IN_CIRCLE_APPLY_NOW_LINK =
  'https://applynow.capitalone.com/?marketingChannelCode=WEB&brandCode=BERGDORFGOODMANPLCC&orchestratorCacheKey=&rLink=https%3A%2F%2Fwww.bergdorfgoodman.com%2Fc%2FBG-Credit-Card-cat289401';
export const ULTIMATE_INSIDER_TITLE = 'THE ULTIMATE INSIDER ACCESS';
export const ULTIMATE_INSIDER_SUB_TITLE = 'Why become an InCircle member?';
export const ALREADY_MEMBER_TITLE = 'Already a member?';
export const ALREADY_MEMBER_SUB_TITLE = 'Sign in now to view your account.';
export const PLAN_ONLINE_PURCHASE_TITLE =
  'Planning to make an online purchase?';
export const PLAN_ONLINE_PURCHASE_SUB_TITLE =
  'You can also apply for a credit card during checkout and use it right away.';
export const IN_CIRCLE_LEVELS_TITLE = 'InCircle Levels';
export const IN_CIRCLE_LEVELS_ANNUAL_SPEND = 'ANNUAL SPEND';
export const IN_CIRCLE_LEVELS_CARDHOLDER_BENEFITS =
  "You'll automatically receive the following cardholder benefits:";
export const IN_CIRCLE_CONCIERGE_PAGE_PATH = '/e/bgincircle-concierge';
export const memberBenefitsData = {
  tableData: [
    [true, true, true, true, true, true],
    [false, true, true, true, true, true],
    [false, true, true, true, true, true],
    [false, false, true, true, true, true],
    [false, false, '$25', false, '$50', '$100'],
    [false, false, false, true, true, true],
  ],
  columnHeaders: [
    'CIRCLE ONE',
    'CIRCLE TWO',
    'CIRCLE THREE',
    'CIRCLE FOUR',
    'CIRCLE FIVE',
    'CIRCLE SIX',
  ],
  columnHeaderSpan: [
    '$1+',
    '$1,000+',
    '$2,500+',
    '$5,000+',
    '$10,000+',
    '$35,000+',
  ],
  columnHeaderSpanMobile: ['$1+', '$1k+', '$2.5k+', '$5k+', '$10k+', '$35k+'],
  rowHeaders: [
    'Bonus-Point Events',
    'Double Point Day',
    'Free 2-Day Shipping',
    'Free Gift Packaging',
    'Perk Card',
    'InCircle Concierge',
  ],
  rowHeaderSpan: [
    '',
    '',
    'online & catalog orders',
    'in stores & online',
    '',
    '',
  ],
};

export const UNEXPECTED_ERROR = {
  title: 'AN UNEXPECTED ERROR OCCURRED',
  desc: 'We’re experiencing technical difficulties. Please check back later.',
};
