import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import AppWrapper from './common/app-common';
import PublicLoyaltyPage from '../myaccount/pages/BgCreditCardPage/PublicLoyaltyPage';

export function AppBgPublicLoyaltyWrapper({ bgoLoyaltyToggle, user, router }) {
  const isLoggedIn = user?.name && user?.securityStatus === 'Authenticated';
  useEffect(() => {
    if (isLoggedIn) {
      router.push('/e/myaccount/bgcreditcard');
    }
  }, []);

  if (!bgoLoyaltyToggle || isLoggedIn) {
    return null;
  }

  return (
    <AppWrapper forceFullBleed>
      <PublicLoyaltyPage />
    </AppWrapper>
  );
}

const mapStateToProps = state => ({
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  user: state.user,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AppBgPublicLoyaltyWrapper),
);
