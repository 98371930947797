import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InCircleLogoLanding from 'assets/images/InCircleLogoLanding.svg';
import {
  PAGE_ID_PUBLIC_LOYALTY_PAGE,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import WhyInCircle from '../NewBgCreditCardPage/WhyInCircle';
import { RESOLVED_INCIRCLE_LOYALTY_PAGE } from '../../../constants';
import { setResolvedPage } from '../../../actions';
import { useConstructor } from '../../../../../utilities/hooks';
import './index.scss';

const PublicLoyaltyPage = ({ setResolvedPage, setPageId }) => {
  useConstructor(() => {
    setPageId(PAGE_ID_PUBLIC_LOYALTY_PAGE);
  });

  useEffect(() => {
    setResolvedPage(RESOLVED_INCIRCLE_LOYALTY_PAGE);
  }, []);

  return (
    <div className="public-loyalty-page">
      <section className="public-loyalty-page__header">
        <img src={InCircleLogoLanding} alt="InCircle" alr="InCircle" />
      </section>
      <section className="public-loyalty-page__content">
        <WhyInCircle />
      </section>
    </div>
  );
};

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  setResolvedPage,
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicLoyaltyPage);
