import {
  SET_PRODUCT_LIST_DATA_TO_UTAG,
  REJECTED_PRODUCT_LIST,
} from 'plp/ProductListPage/actions';
import { types as NavTypes } from '@bgo-ui/common/components/Navigation/actions-navigation';
import toString from 'lodash/toString';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';

const ProductListUtagKeys = {
  categoryResults: 'category_results',
  pageNumber: 'page_number',
  pageType: 'page_type',
  pageTemplate: 'page_template',
  sortMethod: 'sort_method',
  page_definition_id: 'page_definition_id',
  product_view: 'product_view',
  previous_view_filter_submit: 'previous_view_filter_submit',
  previous_view_facet_submit: 'previous_view_facet_submit',
  previous_view_sort_submit: 'previous_view_sort_submit',
  filter_selection: 'filter_selection',
  filter_type: 'filter_type',
  facet_selection: 'facet_selection',
  facet_type: 'facet_type',
  page_name: 'page_name',
  product_cmos_catalog_id: 'product_cmos_catalog_id',
  product_cmos_item: 'product_cmos_item',
  facet_flag: 'facet_flag',
  facet_eligible: 'facet_eligible',
  plpType: 'plp_type',
  grid_view: 'grid_view',
  pcs_enabled: 'pcs_enabled',
  product_id: 'product_id',
  attribution_token: 'attribution_token',
  facet_grs: 'facet_grs',
  sort_method_grs: 'sort_method_grs',
  product_price: 'product_price',
  product_price_original: 'product_price_original',
};

function constructObjectWithNotNullProperties(state, action) {
  const templateType =
    action.payload.templateType === 'EDT'
      ? 'edt plp'
      : action.payload.templateType;
  if (action.payload.facetFlag) {
    return {
      ...state,
      [ProductListUtagKeys.categoryResults]: toString(action.payload.total),
      [ProductListUtagKeys.pageTemplate]: templateType,
      [ProductListUtagKeys.pageNumber]: toString(action.payload.page),
      [ProductListUtagKeys.sortMethod]: action.payload.sortBy,
      [ProductListUtagKeys.page_definition_id]: action.payload.pageDefintionId,
      [ProductListUtagKeys.product_view]: action.payload.product_view,
      [ProductListUtagKeys.previous_view_facet_submit]: toString(
        action.payload.previousViewFacetSubmit,
      ),
      [ProductListUtagKeys.previous_view_sort_submit]: toString(
        action.payload.previousViewSortSubmit,
      ),
      [ProductListUtagKeys.facet_selection]:
        action.payload.facetOptions.filterSelection,
      [ProductListUtagKeys.facet_type]: action.payload.facetOptions.filterType,
      [ProductListUtagKeys.product_cmos_catalog_id]: action.payload.cmosCatalog,
      [ProductListUtagKeys.product_cmos_item]: action.payload.cmosItem,
      [ProductListUtagKeys.facet_flag]: toString(action.payload.facetFlag),
      [ProductListUtagKeys.facet_eligible]: toString(
        action.payload.facetEligible,
      ),
      [ProductListUtagKeys.plpType]: toString(action.payload.plpType),
      [ProductListUtagKeys.grid_view]: toString(action.payload.grid_view),
      [ProductListUtagKeys.pcs_enabled]: toString(action.payload.pcs_enabled),
      [ProductListUtagKeys.product_id]: action.payload.product_id,
      [ProductListUtagKeys.attribution_token]: action.payload.attribution_token,
      [ProductListUtagKeys.facet_grs]: action.payload.facet_grs,
      [ProductListUtagKeys.sort_method_grs]: action.payload.sort_method_grs,
      [ProductListUtagKeys.product_price]: action.payload.product_price,
      [ProductListUtagKeys.product_price_original]:
        action.payload.product_price_original,
    };
  }
  return {
    ...state,
    [ProductListUtagKeys.categoryResults]: toString(action.payload.total),
    [ProductListUtagKeys.pageTemplate]: templateType,
    [ProductListUtagKeys.pageNumber]: toString(action.payload.page),
    [ProductListUtagKeys.sortMethod]: action.payload.sortBy,
    [ProductListUtagKeys.page_definition_id]: action.payload.pageDefintionId,
    [ProductListUtagKeys.product_view]: action.payload.product_view,
    [ProductListUtagKeys.previous_view_filter_submit]: toString(
      action.payload.previousViewFilterSubmit,
    ),
    [ProductListUtagKeys.previous_view_sort_submit]: toString(
      action.payload.previousViewSortSubmit,
    ),
    [ProductListUtagKeys.filter_selection]:
      action.payload.filterOptions.filterSelection,
    [ProductListUtagKeys.filter_type]: action.payload.filterOptions.filterType,
    [ProductListUtagKeys.product_cmos_catalog_id]: action.payload.cmosCatalog,
    [ProductListUtagKeys.product_cmos_item]: action.payload.cmosItem,
    [ProductListUtagKeys.facet_eligible]: toString(
      action.payload.facetEligible,
    ),
    [ProductListUtagKeys.plpType]: toString(action.payload.plpType),
    [ProductListUtagKeys.grid_view]: toString(action.payload.grid_view),
    [ProductListUtagKeys.pcs_enabled]: toString(action.payload.pcs_enabled),
    [ProductListUtagKeys.product_id]: action.payload.product_id,
    [ProductListUtagKeys.attribution_token]: action.payload.attribution_token,
    [ProductListUtagKeys.facet_grs]: action.payload.facet_grs,
    [ProductListUtagKeys.sort_method_grs]: action.payload.sort_method_grs,
    [ProductListUtagKeys.product_price]: action.payload.product_price,
    [ProductListUtagKeys.product_price_original]:
      action.payload.product_price_original,
  };
}

function getPageTypeFromPayload(payload) {
  if (isEmpty(payload.breadcrumbs)) {
    return '';
  }
  if (
    payload.breadcrumbs[0].name &&
    includes(payload.breadcrumbs[0].name.toLowerCase(), 'designers')
  ) {
    return 'boutique';
  }
  return payload.breadcrumbs.length > 2 ? 'subcategory' : 'category';
}

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PRODUCT_LIST_DATA_TO_UTAG:
      return constructObjectWithNotNullProperties(state, action);
    case NavTypes.RESOLVED_BREADCRUMB_CONTENT:
      return {
        ...state,
        [ProductListUtagKeys.page_name]: map(
          action.payload.breadcrumbs,
          'name',
        ).join(':'),
        [ProductListUtagKeys.pageType]: getPageTypeFromPayload(action.payload),
      };
    case NavTypes.REJECTED_BREADCRUMB_CONTENT:
      return {
        ...state,
        [ProductListUtagKeys.page_name]: '',
        [ProductListUtagKeys.pageType]: '',
      };
    case REJECTED_PRODUCT_LIST:
      return {};

    default:
      return state;
  }
};
