import React from 'react';
import AppMyAccountWrapper from './app-my-account-wrapper';
import MyCommunicationPrefPage from '../../myaccount/pages/MyCommunicationPrefPage/MyCommunicationPrefPage';

export default function AppMyCommunicationPrefWrapper() {
  return (
    <AppMyAccountWrapper>
      <MyCommunicationPrefPage />
    </AppMyAccountWrapper>
  );
}
