import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getLoyaltyProfileId } from '@bgo-ui/common/client/utilities/utilities-loyalty';
import Spinner from '@bgo-ui/common/client/common/components/Spinner/spinner';
import {
  PAGE_ID_BG_CREDIT_CARD,
  setPageId,
} from '@bgo-ui/common/client/common/actions/actions-page';
import AppMyAccountWrapper from './app-my-account-wrapper';
import BgCreditCardPage from '../../myaccount/pages/BgCreditCardPage/BgCreditCardPage';
import NewBgCreditCardPage from '../../myaccount/pages/BgCreditCardPage/NewBgCreditCardPage';
import BgNonLoyaltyCreditCardPage from '../../myaccount/pages/BgCreditCardPage/BgNonLoyaltyCreditCardPage';
import { useConstructor } from '../../../utilities/hooks';

const SpinnerComponent = ({ setPageId }) => {
  useConstructor(() => {
    setPageId(PAGE_ID_BG_CREDIT_CARD);
  });

  return <Spinner />;
};

export function AppBgCreditCardWrapper({
  bgoLoyaltyToggle,
  loyHubToggle,
  user,
  amplifyConfig,
  setPageId,
}) {
  const [hasLoyaltyCheckDone, setHasLoyaltyCheckDone] = useState(false);
  const [loyaltyProfileId, setLoyaltyProfileId] = useState('');
  const isLoggedIn = user.name && user.securityStatus === 'Authenticated';

  const isLoyaltyEnabledAccount = async () => {
    if (isLoggedIn && bgoLoyaltyToggle) {
      const id = await getLoyaltyProfileId(user, amplifyConfig);
      setLoyaltyProfileId(id);
    }

    setHasLoyaltyCheckDone(true);
  };

  useEffect(() => {
    isLoyaltyEnabledAccount();
  }, []);

  const renderCreditCardPage = () => {
    if (hasLoyaltyCheckDone) {
      if (isLoggedIn && bgoLoyaltyToggle && !loyaltyProfileId) {
        return <BgNonLoyaltyCreditCardPage />;
      }

      if (bgoLoyaltyToggle && loyHubToggle) {
        return <NewBgCreditCardPage />;
      }

      return <BgCreditCardPage />;
    }

    return <SpinnerComponent setPageId={setPageId} />;
  };

  return <AppMyAccountWrapper>{renderCreditCardPage()}</AppMyAccountWrapper>;
}

const mapStateToProps = state => ({
  bgoLoyaltyToggle: state?.toggles?.BGO_LOYALTY_NEW || false,
  loyHubToggle: state?.toggles?.LOY_HUB || false,
  user: state.user,
  amplifyConfig: state.amplifyConfig,
});

const mapDispatchToProps = {
  setPageId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppBgCreditCardWrapper);
